import React, { useEffect, useState } from 'react';
import { ContentCard } from "../../components/contentCard";
import { Spinner } from "@material-tailwind/react";
import { Paginator } from '../../components/paginator';

  const fetchProducts = async (page) => {
    const response = await fetch(`https://api.cajuhogar.com:8080/product?page=${page}`);
    const data = await response.json();
    return data
};

 const fetchProductByCategory = async (id, page) => {
  const response = await fetch(`https://api.cajuhogar.com:8080/product/category?id=${id}&page=${page}`);
    const data = await response.json();
    return data
 }
  
  export function Product( {idCategory} ) {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [change, setChange] = useState(0);
    const [page, setPage] = useState(0);

    useEffect(() => {
      setPage(0);
      setChange(change + 1);
    }, [idCategory]);

    useEffect(() => {
      setLoading(true);
      if(idCategory === 0 || idCategory === undefined){
      fetchProducts(page).then( data => {
        setData(data)
        setLoading(false);
      });
    }else{
      fetchProductByCategory(idCategory, page).then (
        data => {
          setLoading(true);
          setData(data)
          setLoading(false);
        }
      );
    }
  }, [change,page]);

  if(loading){
    return <>
    <div className="flex items-center justify-center h-screen">
      <Spinner className="h-16 w-16 text-gray-900/50" />
    </div>
    </>
  }
   
    return (
      <section className="container mx-auto">
        <div className="mt-10 grid grid-cols-1 gap-10 lg:grid-cols-3">
          {
          data.products.map((details) => (
            <ContentCard key={details.id} detail={details} />
          ))
          }
        </div>
        <br/>
        <div className="flex items-center justify-center">
          <Paginator pages={data.totalPages} setPages={setPage} pageNumber={page}/>
        </div>
      </section>
    );
  }