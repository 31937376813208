import { Typography } from "@material-tailwind/react";

const currentYear = new Date().getFullYear();

const wppClick = () => {
  const phoneNumber = '573145275089';
  const message = `Hola, busco información sobre CajuHogar!`;

  // Crear la URL para WhatsApp
  const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  // Abrir la URL en una nueva pestaña
  window.open(url, '_blank');
};

export function Footer() {
  return (
    <footer className="px-8">
      <div className="container mx-auto flex flex-col items-center">
      <Typography
                  as="a"
                  href="#"
                  color="white"
                  className="font-medium !text-gray-600"
                >
                  Siguenos en nuestras redes sociales o comunicate con nosotros
                </Typography>
        <br></br>
        <div className="flex flex-wrap items-center justify-center gap-8 pb-8">
                <Typography
                  as="a"
                  href="#"
                  color="white"
                  className="font-medium !text-gray-500 transition-colors hover:!text-gray-900"
                >
                  Facebook
                </Typography>
                <Typography
                  as="a"
                  href="#"
                  color="white"
                  className="font-medium !text-gray-500 transition-colors hover:!text-gray-900"
                  onClick={wppClick}>
                  Whatsapp
                </Typography>
        </div>
        <Typography
          color="blue-gray"
          className="mt-6 !text-sm !font-normal text-gray-500"
        >
          Copyright &copy; {currentYear} CajuHogar
        </Typography>
      </div>
    </footer>
  );
}